// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolioBlock_portfolio_block__\\+n\\+wM {
  max-width: 600px;
  border: 1px solid #fff299;
  border-radius: 16px;
  padding: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #19233c;
}

.portfolioBlock_portfolio_block_image__LyBfd {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid #fff299;
}

.portfolioBlock_portfolio_block_text__aJGBZ {
  padding-top: 30px;
  color: #e6e6e6;
  text-align: center;
  font-size: 24px;
  padding-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/portfolio/portfolioBlock.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".portfolio_block {\n  max-width: 600px;\n  border: 1px solid #fff299;\n  border-radius: 16px;\n  padding: 21px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  background-color: #19233c;\n}\n\n.portfolio_block_image {\n  width: 100%;\n  height: auto;\n  border-radius: 8px;\n  border: 1px solid #fff299;\n}\n\n.portfolio_block_text {\n  padding-top: 30px;\n  color: #e6e6e6;\n  text-align: center;\n  font-size: 24px;\n  padding-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portfolio_block": `portfolioBlock_portfolio_block__+n+wM`,
	"portfolio_block_image": `portfolioBlock_portfolio_block_image__LyBfd`,
	"portfolio_block_text": `portfolioBlock_portfolio_block_text__aJGBZ`
};
export default ___CSS_LOADER_EXPORT___;
