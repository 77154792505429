// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myModal_myModal__xugCu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.myModal_myModalContent__taMjS {
  padding: 25px;
  background: #19233c;
  border-radius: 16px;
  min-width: 250px;
}

.myModal_myModal__xugCu.myModal_active__2xZ6p {
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/modal/myModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,QAAQ;EACR,OAAO;EACP,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".myModal {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  display: none;\n  background: rgba(0, 0, 0, 0.5);\n}\n\n.myModalContent {\n  padding: 25px;\n  background: #19233c;\n  border-radius: 16px;\n  min-width: 250px;\n}\n\n.myModal.active {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myModal": `myModal_myModal__xugCu`,
	"myModalContent": `myModal_myModalContent__taMjS`,
	"active": `myModal_active__2xZ6p`
};
export default ___CSS_LOADER_EXPORT___;
