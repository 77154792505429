// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgBlock_block_item__Z3ah0 {
  padding: 30px 10px;
  color: #fff;
  border-radius: 16px;
  font-size: 20px;
  list-style: none;
  width: 32%;
  text-align: center;
  background: transparent;
  border: 1px solid #fff299;
}

.imgBlock_block_item_image__rBKnU {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid #fff299;
  border-radius: 16px;
}

.imgBlock_block_item__Z3ah0 h2 {
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.imgBlock_block_item_text__GvArr {
  color: #fff;
  margin-top: 10px;
  line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/homepage/homepageImgBlock/imgBlock.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".block_item {\n  padding: 30px 10px;\n  color: #fff;\n  border-radius: 16px;\n  font-size: 20px;\n  list-style: none;\n  width: 32%;\n  text-align: center;\n  background: transparent;\n  border: 1px solid #fff299;\n}\n\n.block_item_image {\n  width: 100%;\n  height: auto;\n  margin-bottom: 10px;\n  border: 1px solid #fff299;\n  border-radius: 16px;\n}\n\n.block_item h2 {\n  color: #fff;\n  font-size: 24px;\n  text-transform: uppercase;\n  margin-bottom: 16px;\n}\n\n.block_item_text {\n  color: #fff;\n  margin-top: 10px;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block_item": `imgBlock_block_item__Z3ah0`,
	"block_item_image": `imgBlock_block_item_image__rBKnU`,
	"block_item_text": `imgBlock_block_item_text__GvArr`
};
export default ___CSS_LOADER_EXPORT___;
