// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  background-color: #2c3247;
  padding: 40px 20px 0px 20px;
  min-height: calc(100vh - 210px);
}

.container {
  max-width: 1575px;
  margin: 0 auto;
}

.about-header {
  width: 100%;
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
  padding-top: 40px;
  font-size: 36px;
  letter-spacing: 1.5px;
}

.about-content {
  display: flex;
  justify-content: center;
}

.about-blocks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px;
  padding: 0;
  list-style: none;
  max-width: 100%;
  margin: 0 auto;
}

.about-blocks li {
  border: 1px solid #fff299;
  background-color: #3b4053;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-blocks li:hover {
  transform: translateY(-5px);
}

.about-blocks a {
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  line-height: 1.6;
}

.about-blocks p {
  margin-top: 10px;
  color: #fff;
  font-size: 20px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .about-blocks {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/about/about.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,cAAc;EACd,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,+BAA+B;AACjC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".about {\n  background-color: #2c3247;\n  padding: 40px 20px 0px 20px;\n  min-height: calc(100vh - 210px);\n}\n\n.container {\n  max-width: 1575px;\n  margin: 0 auto;\n}\n\n.about-header {\n  width: 100%;\n  color: #fff;\n  text-align: center;\n  margin-bottom: 40px;\n  padding-top: 40px;\n  font-size: 36px;\n  letter-spacing: 1.5px;\n}\n\n.about-content {\n  display: flex;\n  justify-content: center;\n}\n\n.about-blocks {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n  grid-gap: 40px;\n  padding: 0;\n  list-style: none;\n  max-width: 100%;\n  margin: 0 auto;\n}\n\n.about-blocks li {\n  border: 1px solid #fff299;\n  background-color: #3b4053;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  transition: transform 0.3s ease;\n}\n\n.about-blocks li:hover {\n  transform: translateY(-5px);\n}\n\n.about-blocks a {\n  text-decoration: none;\n  color: #fff;\n  font-size: 24px;\n  line-height: 1.6;\n}\n\n.about-blocks p {\n  margin-top: 10px;\n  color: #fff;\n  font-size: 20px;\n  line-height: 1.6;\n}\n\n@media (max-width: 768px) {\n  .about-blocks {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
