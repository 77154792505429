// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-details {
  min-height: calc(100vh - 210px);
  background-color: #2c3247;
  color: #e6e6e6;
  padding: 0px 20px;
}
.container {
  max-width: 1575px;
  margin: 0 auto;
}
.service-header {
  text-align: center;
  font-size: 36px;
  color: #fff;
  padding-top: 75px;
  margin-bottom: 40px;
}

.service-info p {
  font-size: 20px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/services/subServices/servicePages.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,yBAAyB;EACzB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".work-details {\n  min-height: calc(100vh - 210px);\n  background-color: #2c3247;\n  color: #e6e6e6;\n  padding: 0px 20px;\n}\n.container {\n  max-width: 1575px;\n  margin: 0 auto;\n}\n.service-header {\n  text-align: center;\n  font-size: 36px;\n  color: #fff;\n  padding-top: 75px;\n  margin-bottom: 40px;\n}\n\n.service-info p {\n  font-size: 20px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
