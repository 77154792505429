import React from "react";
import styles from "./company.module.css";

function Company() {
  return (
    <div className={styles.companyContainer}>
      <h1 className={styles.title}>О НАС</h1>
      <div className={styles.textBlock}>
        <p>
          «LUNARIS» — компания с более чем 20-летней историей постоянного
          совершенствования мастерства в разработке и продвижении сайтов.
        </p>
        <p>
          За все время существования, компанией было реализовано более 500
          проектов для коммерческих, государственных и муниципальных заказчиков
          во многих, Москве и других городов России.
        </p>
        <p>
          Свою деятельность компания «Lunaris» начала в 2003 году. В настоящий
          момент в штате компании состоит более 30 специалистов в области
          веб-дизайна, верстки, программирования, веб-контента и продвижения
          сайтов.
        </p>

        <h3>Основные направления деятельности компании являются:</h3>
        <ul>
          <li>Создание сайтов;</li>
          <li>Поддержка сайтов;</li>
          <li>Продвижение сайтов.</li>
        </ul>

        <h3>Работа строится на основании следующих принципов:</h3>
        <ul>
          <li>
            Создание проекта сочетает в себе креативные идеи и технологичное
            исполнение.
          </li>
          <li>
            Благодаря профессионализму и квалификации сотрудников, даем гарантию
            качества на результат их деятельности, то есть на все наши проекты.
          </li>
          <li>
            Максимальная эффективность – на первом месте. Основная задача –
            предоставить клиенту индивидуальное эффективное решение, чтобы
            клиент мог достичь свои цели.
          </li>
          <li>
            Клиентоориентированная компания. Мы совершенствуем качество своих
            услуг, чтобы оправдать и предвосхитить ожидания наших клиентов.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Company;
