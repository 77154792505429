// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutBlock_about_block__MHK6R {
  background: #19233c;
  max-width: 600px;
  width: 100%;
  border-radius: 15px;

  padding: 20px;
}

.aboutBlock_about_block_text__Lyq6n {
  font-size: 24px;
  color: #fff;
}

.aboutBlock_about_block_link__JsSuD {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/about/aboutBlock.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;;EAEnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".about_block {\n  background: #19233c;\n  max-width: 600px;\n  width: 100%;\n  border-radius: 15px;\n\n  padding: 20px;\n}\n\n.about_block_text {\n  font-size: 24px;\n  color: #fff;\n}\n\n.about_block_link {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about_block": `aboutBlock_about_block__MHK6R`,
	"about_block_text": `aboutBlock_about_block_text__Lyq6n`,
	"about_block_link": `aboutBlock_about_block_link__JsSuD`
};
export default ___CSS_LOADER_EXPORT___;
