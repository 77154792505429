// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employeeCard_card__SdzHi {
  background-color: #2e324b;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.employeeCard_image__h2N5H {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.employeeCard_name__RWmNp {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.employeeCard_role__xxX0e {
  font-size: 14px;
  color: #a5a5a5;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/about/aboutPages/employeeCard.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".card {\n  background-color: #2e324b;\n  padding: 20px;\n  border-radius: 10px;\n  text-align: center;\n}\n\n.image {\n  width: 100%;\n  height: auto;\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n\n.name {\n  font-size: 18px;\n  font-weight: bold;\n  margin-bottom: 5px;\n}\n\n.role {\n  font-size: 14px;\n  color: #a5a5a5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `employeeCard_card__SdzHi`,
	"image": `employeeCard_image__h2N5H`,
	"name": `employeeCard_name__RWmNp`,
	"role": `employeeCard_role__xxX0e`
};
export default ___CSS_LOADER_EXPORT___;
