// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.googleForm_googleFormButton__pz7bu {
  float: right;
  margin-bottom: 20px;
  display: inline-flex;
  padding: 10px 20px;
  background-color: #d3d3c4;
  color: #1c1c1c;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 36px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.googleForm_googleFormButton__pz7bu:hover {
  background-color: #bebea8;
  transform: scale(1.05);
}

.googleForm_googleFormButton__pz7bu:active {
  background-color: #a5a591;
  transform: scale(1);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/services/servUi/googleForm/googleForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,6BAA6B;EAC7B,2DAA2D;AAC7D;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".googleFormButton {\n  float: right;\n  margin-bottom: 20px;\n  display: inline-flex;\n  padding: 10px 20px;\n  background-color: #d3d3c4;\n  color: #1c1c1c;\n  text-align: center;\n  text-decoration: none;\n  font-weight: bold;\n  font-size: 36px;\n  border-radius: 5px;\n  border: 2px solid transparent;\n  transition: background-color 0.3s ease, transform 0.3s ease;\n}\n\n.googleFormButton:hover {\n  background-color: #bebea8;\n  transform: scale(1.05);\n}\n\n.googleFormButton:active {\n  background-color: #a5a591;\n  transform: scale(1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"googleFormButton": `googleForm_googleFormButton__pz7bu`
};
export default ___CSS_LOADER_EXPORT___;
