// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workItem_work_item__bp1VV {
  background-color: #333a50;
  padding: 20px;
  border-radius: 16px;
  border: solid 1px #fff299;
}

.workItem_work_image__qDPHv {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.workItem_work_info__DytfK {
  margin-top: 15px;
}

.workItem_work_title__E-v-F {
  font-size: 18px;
  color: #e6e6e6;
  margin-bottom: 10px;
}

.workItem_work_description__NUL9i {
  font-size: 14px;
  color: #e6e6e6;
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/portfolio/portfolioPages/workItem.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,YAAY;AACd","sourcesContent":[".work_item {\n  background-color: #333a50;\n  padding: 20px;\n  border-radius: 16px;\n  border: solid 1px #fff299;\n}\n\n.work_image {\n  width: 100%;\n  height: auto;\n  border-radius: 8px;\n}\n\n.work_info {\n  margin-top: 15px;\n}\n\n.work_title {\n  font-size: 18px;\n  color: #e6e6e6;\n  margin-bottom: 10px;\n}\n\n.work_description {\n  font-size: 14px;\n  color: #e6e6e6;\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"work_item": `workItem_work_item__bp1VV`,
	"work_image": `workItem_work_image__qDPHv`,
	"work_info": `workItem_work_info__DytfK`,
	"work_title": `workItem_work_title__E-v-F`,
	"work_description": `workItem_work_description__NUL9i`
};
export default ___CSS_LOADER_EXPORT___;
