// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workExamples_work_examples__Y1kWo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}

.workExamples_work_examples__Y1kWo strong {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}

.workExamples_example_pictures__diLyL {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adjust the spacing between images */
}

.workExamples_example_img__FbbtC {
  max-width: 50%; /* Adjust the width according to your image dimensions */
  height: auto;
  border-radius: 16px;
  border: 1px solid #fff299;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/services/servUi/workExamples/workExamples.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS,EAAE,sCAAsC;AACnD;;AAEA;EACE,cAAc,EAAE,wDAAwD;EACxE,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".work_examples {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 40px 0;\n}\n\n.work_examples strong {\n  font-size: 24px;\n  color: #fff;\n  margin-bottom: 20px;\n}\n\n.example_pictures {\n  display: flex;\n  justify-content: space-between;\n  gap: 10px; /* Adjust the spacing between images */\n}\n\n.example_img {\n  max-width: 50%; /* Adjust the width according to your image dimensions */\n  height: auto;\n  border-radius: 16px;\n  border: 1px solid #fff299;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"work_examples": `workExamples_work_examples__Y1kWo`,
	"example_pictures": `workExamples_example_pictures__diLyL`,
	"example_img": `workExamples_example_img__FbbtC`
};
export default ___CSS_LOADER_EXPORT___;
