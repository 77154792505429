// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awardCard_card__dCYj2 {
  background-color: #2e324b;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.awardCard_image__pdC3c {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.awardCard_title__gburj {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/portfolio/portfolioPages/awardCard.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".card {\n  background-color: #2e324b;\n  padding: 20px;\n  border-radius: 10px;\n  text-align: center;\n}\n\n.image {\n  width: 100%;\n  height: auto;\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n\n.title {\n  font-size: 20px;\n  color: #ffffff;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `awardCard_card__dCYj2`,
	"image": `awardCard_image__pdC3c`,
	"title": `awardCard_title__gburj`
};
export default ___CSS_LOADER_EXPORT___;
