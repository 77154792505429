// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page__wrapper {
  margin-top: 30px;
  display: flex;
}
.page {
  padding: 10px;
  border: 1px solid #fff299;
  cursor: pointer;
}
.page__current {
  border: 2px solid #a5a591;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/pagination/pagination.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".page__wrapper {\n  margin-top: 30px;\n  display: flex;\n}\n.page {\n  padding: 10px;\n  border: 1px solid #fff299;\n  cursor: pointer;\n}\n.page__current {\n  border: 2px solid #a5a591;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
