// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myButton_myButton__EHcnM {
  padding: 5px 15px;
  color: #fff;
  font-size: 16px;
  background: transparent;
  border: 1px solid #fff299;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/button/myButton.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,uBAAuB;EACvB,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".myButton {\n  padding: 5px 15px;\n  color: #fff;\n  font-size: 16px;\n  background: transparent;\n  border: 1px solid #fff299;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myButton": `myButton_myButton__EHcnM`
};
export default ___CSS_LOADER_EXPORT___;
