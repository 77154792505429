// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #19233c;
  color: #fff;
}

.container {
  max-width: 1575px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_text {
  font-size: 20px;
}

.footer-logo {
  height: 140px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".footer {\n  background-color: #19233c;\n  color: #fff;\n}\n\n.container {\n  max-width: 1575px;\n  margin: 0 auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.footer_text {\n  font-size: 20px;\n}\n\n.footer-logo {\n  height: 140px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
