// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* servicesImgBlock.module.css */

.servicesImgBlock_block__6pUJc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 10px 0;

  padding: 10px 10px;
  background: transparent;
  border: 1px solid #fff299;
  cursor: pointer;
  border-radius: 16px;
}

.servicesImgBlock_block__6pUJc img {
  border: 1px solid #fff299;
  max-width: 40%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.servicesImgBlock_h2__Yc6nq {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
}

.servicesImgBlock_block__6pUJc p {
  font-size: 20px;
  color: #fff;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/services/servUi/clickableBlock/servicesImgBlock.module.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,cAAc;;EAEd,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;EACzB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB","sourcesContent":["/* servicesImgBlock.module.css */\n\n.block {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 15px;\n  align-items: center;\n  width: 100%;\n  text-align: center;\n  margin: 10px 0;\n\n  padding: 10px 10px;\n  background: transparent;\n  border: 1px solid #fff299;\n  cursor: pointer;\n  border-radius: 16px;\n}\n\n.block img {\n  border: 1px solid #fff299;\n  max-width: 40%;\n  height: auto;\n  border-radius: 10px;\n  margin-bottom: 15px;\n}\n\n.h2 {\n  font-size: 24px;\n  margin-bottom: 10px;\n  color: #fff;\n}\n\n.block p {\n  font-size: 20px;\n  color: #fff;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `servicesImgBlock_block__6pUJc`,
	"h2": `servicesImgBlock_h2__Yc6nq`
};
export default ___CSS_LOADER_EXPORT___;
