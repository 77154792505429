// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body {
  background-color: #2c3247;
  width: 100%;
  min-height: calc(100vh - 210px);
}
.container {
  max-width: 1575px;
  padding: 0 75px;
  margin: 0 auto;
}

.block-header {
  color: #e6e6e6;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 70px;
  font-size: 32px;
}

.block-row {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}

.block-table {
  width: 80%;
  height: 275px;
  border-radius: 16px;
  background: transparent;
  border: 1px solid #fff299;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 50px;
  color: #fff;
  font-size: 24px;
  margin: 0 auto;
}
.block-table-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.about-us-text {
  color: #fff;
  font-size: 24px;
  text-align: block;
}

.news {
  margin: auto;
}

.newsImg {
  max-width: 80%;
  height: auto;
  border: 1px solid #fff299;
  border-radius: 16px;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.adjust_about {
  margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/homepage/homepageBody.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,+BAA+B;AACjC;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;AACA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;;EAEnB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".body {\n  background-color: #2c3247;\n  width: 100%;\n  min-height: calc(100vh - 210px);\n}\n.container {\n  max-width: 1575px;\n  padding: 0 75px;\n  margin: 0 auto;\n}\n\n.block-header {\n  color: #e6e6e6;\n  text-transform: uppercase;\n  text-align: center;\n  margin-bottom: 20px;\n  padding-top: 70px;\n  font-size: 32px;\n}\n\n.block-row {\n  display: flex;\n  justify-content: space-around;\n  gap: 10px;\n  flex-wrap: wrap;\n}\n\n.block-table {\n  width: 80%;\n  height: 275px;\n  border-radius: 16px;\n  background: transparent;\n  border: 1px solid #fff299;\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  padding: 20px 50px;\n  color: #fff;\n  font-size: 24px;\n  margin: 0 auto;\n}\n.block-table-column {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n}\n.about-us-text {\n  color: #fff;\n  font-size: 24px;\n  text-align: block;\n}\n\n.news {\n  margin: auto;\n}\n\n.newsImg {\n  max-width: 80%;\n  height: auto;\n  border: 1px solid #fff299;\n  border-radius: 16px;\n\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n.adjust_about {\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
