// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio {
  display: flex;
  flex-direction: column;
  background-color: #2c3247;
  padding: 75px 20px 0px 20px;
  min-height: calc(100vh - 210px);
}

.container {
  max-width: 1575px;
  margin: 0 auto;
}

.portfolio-header {
  font-size: 36px;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}

.portfolio-blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.portfolio-block {
  background-color: #3a3f57;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #6d7395;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 20px);
  max-width: 100%;
  text-align: center;
}

.portfolio-block img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.portfolio-block p {
  color: #e6e6e6;
  font-size: 18px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .portfolio-blocks {
    flex-direction: column;
    align-items: center;
  }

  .portfolio-block {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/portfolio/portfolio.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,2BAA2B;EAC3B,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,yBAAyB;EACzB,YAA0B;EAA1B,cAA0B;EAA1B,4BAA0B;EAC1B,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":[".portfolio {\n  display: flex;\n  flex-direction: column;\n  background-color: #2c3247;\n  padding: 75px 20px 0px 20px;\n  min-height: calc(100vh - 210px);\n}\n\n.container {\n  max-width: 1575px;\n  margin: 0 auto;\n}\n\n.portfolio-header {\n  font-size: 36px;\n  color: #fff;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.portfolio-blocks {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  gap: 20px;\n  margin-bottom: 40px;\n}\n\n.portfolio-block {\n  background-color: #3a3f57;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border: 1px solid #6d7395;\n  flex: 1 1 calc(50% - 20px);\n  max-width: 100%;\n  text-align: center;\n}\n\n.portfolio-block img {\n  max-width: 100%;\n  height: auto;\n  margin-bottom: 15px;\n}\n\n.portfolio-block p {\n  color: #e6e6e6;\n  font-size: 18px;\n  line-height: 1.6;\n}\n\n@media (max-width: 768px) {\n  .portfolio-blocks {\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .portfolio-block {\n    width: 100%;\n    max-width: 500px;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
