// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.events {
  display: flex;
  margin: auto;
  background-color: #2c3247;
  color: #e6e6e6;
  padding: 75px 20px;
  min-height: calc(100vh - 210px);
}

.events-header {
  text-align: center;
  font-size: 36px;
  color: #fff;
  margin-bottom: 40px;
}
.posts {
  display: flex;
  flex-direction: column;
}

.post {
  width: 100%;
  display: flex;
  padding: 15px;
  border: 2px solid #fff299;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}
.loading {
  display: flex;
  justify-content: center;
}
.post__btns {
  display: flex;
}
.pag {
  display: flex;

  justify-content: space-around;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/events/events.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;;EAEb,6BAA6B;AAC/B","sourcesContent":[".events {\n  display: flex;\n  margin: auto;\n  background-color: #2c3247;\n  color: #e6e6e6;\n  padding: 75px 20px;\n  min-height: calc(100vh - 210px);\n}\n\n.events-header {\n  text-align: center;\n  font-size: 36px;\n  color: #fff;\n  margin-bottom: 40px;\n}\n.posts {\n  display: flex;\n  flex-direction: column;\n}\n\n.post {\n  width: 100%;\n  display: flex;\n  padding: 15px;\n  border: 2px solid #fff299;\n  margin-top: 15px;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  max-width: 1200px;\n}\n.loading {\n  display: flex;\n  justify-content: center;\n}\n.post__btns {\n  display: flex;\n}\n.pag {\n  display: flex;\n\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
