// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a, a:link, a:visited  {
    text-decoration: none;
}

a:hover  {
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
	font-weight: inherit;
}

ul, ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img, svg {
	max-width: 100%;
	height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}
`, "",{"version":3,"sources":["webpack://./src/styles/reset.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;CACC,YAAY;CACZ,WAAW;CACX,YAAY;AACb;;AAEA;;;CAGC,sBAAsB;AACvB;;AAEA,UAAU;;AAEV;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA,WAAW;;AAEX;CACC,cAAc;AACf;;AAEA;IACI,kBAAkB;CACrB,oBAAoB;AACrB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,YAAY;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA,SAAS;;AAET;CACC,oBAAoB;IACjB,kBAAkB;IAClB,cAAc;IACd,6BAA6B;AACjC;;AAEA;CACC,aAAa;AACd;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;CACC,UAAU;CACV,SAAS;AACV;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,cAAc;AACf","sourcesContent":["/* Reset and base styles  */\n* {\n\tpadding: 0px;\n\tmargin: 0px;\n\tborder: none;\n}\n\n*,\n*::before,\n*::after {\n\tbox-sizing: border-box;\n}\n\n/* Links */\n\na, a:link, a:visited  {\n    text-decoration: none;\n}\n\na:hover  {\n    text-decoration: none;\n}\n\n/* Common */\n\naside, nav, footer, header, section, main {\n\tdisplay: block;\n}\n\nh1, h2, h3, h4, h5, h6, p {\n    font-size: inherit;\n\tfont-weight: inherit;\n}\n\nul, ul li {\n\tlist-style: none;\n}\n\nimg {\n\tvertical-align: top;\n}\n\nimg, svg {\n\tmax-width: 100%;\n\theight: auto;\n}\n\naddress {\n  font-style: normal;\n}\n\n/* Form */\n\ninput, textarea, button, select {\n\tfont-family: inherit;\n    font-size: inherit;\n    color: inherit;\n    background-color: transparent;\n}\n\ninput::-ms-clear {\n\tdisplay: none;\n}\n\nbutton, input[type=\"submit\"] {\n    display: inline-block;\n    box-shadow: none;\n    background-color: transparent;\n    background: none;\n    cursor: pointer;\n}\n\ninput:focus, input:active,\nbutton:focus, button:active {\n    outline: none;\n}\n\nbutton::-moz-focus-inner {\n\tpadding: 0;\n\tborder: 0;\n}\n\nlabel {\n\tcursor: pointer;\n}\n\nlegend {\n\tdisplay: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
