// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services {
  display: flex;
  flex-direction: column;
  background-color: #2c3247;
  padding: 75px 20px 0px 20px;
  min-height: calc(100vh - 210px);
}
.container {
  max-width: 1575px;
  margin: 0 auto;
}

.services-header {
  font-size: 36px;
  color: #fff;
}

.services-block {
  max-width: 1200px;
  width: 100%;
  border-radius: 15px;
  padding: 20px;
}

.services-block-text {
  font-size: 24px;
  color: #fff;
}

.services-block-link {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/services/services.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,2BAA2B;EAC3B,+BAA+B;AACjC;AACA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".services {\n  display: flex;\n  flex-direction: column;\n  background-color: #2c3247;\n  padding: 75px 20px 0px 20px;\n  min-height: calc(100vh - 210px);\n}\n.container {\n  max-width: 1575px;\n  margin: 0 auto;\n}\n\n.services-header {\n  font-size: 36px;\n  color: #fff;\n}\n\n.services-block {\n  max-width: 1200px;\n  width: 100%;\n  border-radius: 15px;\n  padding: 20px;\n}\n\n.services-block-text {\n  font-size: 24px;\n  color: #fff;\n}\n\n.services-block-link {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
