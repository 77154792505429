// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.communication {
  background-color: #2c3247;
  min-height: calc(100vh - 210px);
  padding: 40px 20px;
}

.contacts-container {
  text-align: center;
  color: #ffffff;
  max-width: 1000px;
  margin: 0 auto;
}

.contacts-title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}

.contacts-info-wrapper {
  background: #19233c;
  border: 1px solid #fff299;
  border-radius: 16px;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.contacts-info {
  flex: 1 1;
  min-width: 250px;
  margin-bottom: 30px;
}

.contacts-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.contacts-description {
  font-size: 18px;
  line-height: 1.6;
}

.contact-person {
  margin-bottom: 20px;
}

.contact-name {
  font-size: 20px;
  font-weight: bold;
}

.contact-position {
  font-size: 18px;
}

.contact-phone,
.contact-email {
  font-size: 16px;
}

@media (max-width: 768px) {
  .contacts-info-wrapper {
    flex-direction: column;
  }

  .contacts-container {
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/communication/communication.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".communication {\n  background-color: #2c3247;\n  min-height: calc(100vh - 210px);\n  padding: 40px 20px;\n}\n\n.contacts-container {\n  text-align: center;\n  color: #ffffff;\n  max-width: 1000px;\n  margin: 0 auto;\n}\n\n.contacts-title {\n  font-size: 32px;\n  font-weight: bold;\n  text-align: center;\n  margin-bottom: 40px;\n}\n\n.contacts-info-wrapper {\n  background: #19233c;\n  border: 1px solid #fff299;\n  border-radius: 16px;\n  padding: 20px 0px;\n  display: flex;\n  justify-content: space-between;\n  gap: 40px;\n  flex-wrap: wrap;\n}\n\n.contacts-info {\n  flex: 1;\n  min-width: 250px;\n  margin-bottom: 30px;\n}\n\n.contacts-subtitle {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n.contacts-description {\n  font-size: 18px;\n  line-height: 1.6;\n}\n\n.contact-person {\n  margin-bottom: 20px;\n}\n\n.contact-name {\n  font-size: 20px;\n  font-weight: bold;\n}\n\n.contact-position {\n  font-size: 18px;\n}\n\n.contact-phone,\n.contact-email {\n  font-size: 16px;\n}\n\n@media (max-width: 768px) {\n  .contacts-info-wrapper {\n    flex-direction: column;\n  }\n\n  .contacts-container {\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
