// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-works {
  min-height: calc(100vh - 210px);

  background-color: #2c3247;
  padding: 50px 0;
  text-align: center;
}

.works-title {
  font-size: 36px;
  color: #e6e6e6;
  margin-bottom: 40px;
}

.works-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/portfolio/portfolioPages/ourWorks.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;;EAE/B,yBAAyB;EACzB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".our-works {\n  min-height: calc(100vh - 210px);\n\n  background-color: #2c3247;\n  padding: 50px 0;\n  text-align: center;\n}\n\n.works-title {\n  font-size: 36px;\n  color: #e6e6e6;\n  margin-bottom: 40px;\n}\n\n.works-grid {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 30px;\n  max-width: 1200px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
