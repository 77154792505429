// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awards {
  background-color: #2c3247;
  min-height: calc(100vh - 210px);
  padding: 40px;
}
.awardsContainer {
  max-width: 900px;
  margin: 0 auto;

  color: #ffffff;
  padding: 40px;
  text-align: center;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.description {
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.5;
}

.awardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/portfolio/portfolioPages/ourAwards.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,cAAc;;EAEd,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;AACX","sourcesContent":[".awards {\n  background-color: #2c3247;\n  min-height: calc(100vh - 210px);\n  padding: 40px;\n}\n.awardsContainer {\n  max-width: 900px;\n  margin: 0 auto;\n\n  color: #ffffff;\n  padding: 40px;\n  text-align: center;\n}\n\n.title {\n  font-size: 30px;\n  margin-bottom: 20px;\n  letter-spacing: 1px;\n}\n\n.description {\n  font-size: 20px;\n  max-width: 800px;\n  margin: 0 auto 40px;\n  line-height: 1.5;\n}\n\n.awardsGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
