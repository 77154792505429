// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myInput_myInput__juXdj {
  width: 100%;
  padding: 5px 15px;
  margin: 5px 0;
  border: 1px solid #fff299;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/input/myInput.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".myInput {\n  width: 100%;\n  padding: 5px 15px;\n  margin: 5px 0;\n  border: 1px solid #fff299;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myInput": `myInput_myInput__juXdj`
};
export default ___CSS_LOADER_EXPORT___;
