// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company_companyContainer__qYzW- {
  background-color: #2c3247;
  color: #ffffff;
  padding: 40px;
  text-align: center;
  line-height: 1.1;
  min-height: calc(100vh - 210px);
}

.company_title__22fiq {
  margin-top: 40px;
  font-size: 32px;

  margin-bottom: 20px;
  letter-spacing: 1px;
}

.company_textBlock__IfO7s {
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.company_textBlock__IfO7s h3 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.company_textBlock__IfO7s ul {
  list-style-type: circle;

  padding: 0;
  margin-top: 10px;
}

.company_textBlock__IfO7s li {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/about/aboutPages/company.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,eAAe;;EAEf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;;EAEvB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".companyContainer {\n  background-color: #2c3247;\n  color: #ffffff;\n  padding: 40px;\n  text-align: center;\n  line-height: 1.1;\n  min-height: calc(100vh - 210px);\n}\n\n.title {\n  margin-top: 40px;\n  font-size: 32px;\n\n  margin-bottom: 20px;\n  letter-spacing: 1px;\n}\n\n.textBlock {\n  font-size: 20px;\n  max-width: 800px;\n  margin: 0 auto;\n  text-align: left;\n}\n\n.textBlock h3 {\n  margin-top: 20px;\n  font-size: 18px;\n  font-weight: bold;\n}\n\n.textBlock ul {\n  list-style-type: circle;\n\n  padding: 0;\n  margin-top: 10px;\n}\n\n.textBlock li {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyContainer": `company_companyContainer__qYzW-`,
	"title": `company_title__22fiq`,
	"textBlock": `company_textBlock__IfO7s`
};
export default ___CSS_LOADER_EXPORT___;
